import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  VStack,
  HStack,
  Text,
  Input,
  Button,
  List,
  ListItem,
  useToast,
  Circle,
  Textarea,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';

const Settings = () => {
  const [feeds, setFeeds] = useState([]);
  const [newFeedUrl, setNewFeedUrl] = useState('');
  const [editingFeed, setEditingFeed] = useState(null);
  const [promptTemplate, setPromptTemplate] = useState('');
  const [lookBackDays, setLookBackDays] = useState(2);
  const toast = useToast();

  useEffect(() => {
    fetchFeeds();
    fetchPromptTemplate();
    fetchLookBackDays();
  }, []);

  const fetchFeeds = async () => {
    try {
      const response = await axios.get('/api/rss/feeds');
      setFeeds(response.data);
    } catch (error) {
      console.error('Error fetching feeds:', error);
      toast({
        title: 'Error fetching feeds',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchPromptTemplate = async () => {
    try {
      const response = await axios.get('/api/settings/prompt-template');
      setPromptTemplate(response.data.promptTemplate);
    } catch (error) {
      console.error('Error fetching prompt template:', error);
    }
  };

  const addFeed = async () => {
    try {
      await axios.post('/api/rss/feeds', { url: newFeedUrl });
      setNewFeedUrl('');
      fetchFeeds();
      toast({
        title: 'Feed added successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding feed:', error);
      toast({
        title: 'Error adding feed',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const deleteFeed = async (id) => {
    try {
      await axios.delete(`/api/rss/feeds/${id}`);
      fetchFeeds();
      toast({
        title: 'Feed deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting feed:', error);
      toast({
        title: 'Error deleting feed',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const startEditing = (feed) => {
    setEditingFeed({ ...feed });
  };

  const cancelEditing = () => {
    setEditingFeed(null);
  };

  const saveEdit = async () => {
    try {
      await axios.put(`/api/rss/feeds/${editingFeed._id}`, { url: editingFeed.url });
      setEditingFeed(null);
      fetchFeeds();
      toast({
        title: 'Feed updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating feed:', error);
      toast({
        title: 'Error updating feed',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handlePromptTemplateChange = (event) => {
    setPromptTemplate(event.target.value);
  };

  const handleSavePromptTemplate = async () => {
    try {
      await axios.put('/api/settings/prompt-template', { promptTemplate });
      toast({
        title: 'Prompt template updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating prompt template:', error);
      toast({
        title: 'Error updating prompt template',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchLookBackDays = async () => {
    try {
      const response = await axios.get('/api/settings/look-back-days');
      setLookBackDays(response.data.lookBackDays);
    } catch (error) {
      console.error('Error fetching look back days:', error);
    }
  };

  const handleLookBackDaysChange = (value) => {
    setLookBackDays(value);
  };

  const handleSaveLookBackDays = async () => {
    try {
      await axios.put('/api/settings/look-back-days', { lookBackDays: parseInt(lookBackDays, 10) });
      toast({
        title: 'Look back days updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating look back days:', error);
      toast({
        title: 'Error updating look back days',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const SaveButton = ({ onClick, children }) => (
    <Button
      onClick={onClick}
      colorScheme="teal"
      size="md"
      width="100px"
    >
      {children}
    </Button>
  );

  return (
    <Box maxWidth="800px" margin="auto" mt={8}>
      <VStack spacing={4} align="stretch">
        <Text fontSize="2xl" fontWeight="bold">RSS Feed Settings</Text>
        <List spacing={3}>
          {feeds.map((feed) => (
            <ListItem key={feed._id}>
              <HStack>
                {editingFeed && editingFeed._id === feed._id ? (
                  <>
                    <Input
                      value={editingFeed.url}
                      onChange={(e) => setEditingFeed({ ...editingFeed, url: e.target.value })}
                    />
                    <SaveButton onClick={saveEdit}>Save</SaveButton>
                    <Button onClick={cancelEditing} size="md" width="100px">Cancel</Button>
                  </>
                ) : (
                  <>
                    <Text flex={1}>{feed.url}</Text>
                    <Circle size="10px" bg={feed.hasError ? 'red.500' : 'green.500'} mr={2} />
                    <Button
                      size="sm"
                      onClick={() => startEditing(feed)}
                    >
                      Edit
                    </Button>
                    <Button
                      size="sm"
                      onClick={() => deleteFeed(feed._id)}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </HStack>
            </ListItem>
          ))}
        </List>
        <HStack>
          <Input
            placeholder="Enter new RSS feed URL"
            value={newFeedUrl}
            onChange={(e) => setNewFeedUrl(e.target.value)}
          />
          <SaveButton onClick={addFeed}>Add Feed</SaveButton>
        </HStack>
      </VStack>
      <Box mt={8}>
        <FormControl>
          <FormLabel htmlFor="promptTemplate" fontWeight="bold">Prompt Template:</FormLabel>
          <Textarea
            id="promptTemplate"
            value={promptTemplate}
            onChange={handlePromptTemplateChange}
            rows="10"
            placeholder="Enter your prompt template here"
          />
        </FormControl>
        <Box mt={4}>
          <SaveButton onClick={handleSavePromptTemplate}>Save</SaveButton>
        </Box>
      </Box>
      <Box mt={8}>
        <FormControl>
          <FormLabel htmlFor="lookBackDays" fontWeight="bold">Look Back Days:</FormLabel>
          <HStack>
            <NumberInput
              id="lookBackDays"
              value={lookBackDays}
              onChange={handleLookBackDaysChange}
              min={1}
              max={30}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <SaveButton onClick={handleSaveLookBackDays}>Save</SaveButton>
          </HStack>
        </FormControl>
      </Box>
    </Box>
  );
};

export default Settings;