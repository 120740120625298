import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import { ChakraProvider, Box, VStack, Heading, Button } from '@chakra-ui/react';
import Home from './pages/Home';
import Settings from './pages/Settings';
import Header from './components/Header';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      const response = await axios.get('/api/auth/status');
      setIsAuthenticated(response.data.isAuthenticated);
    } catch (error) {
      console.error('Error checking auth status:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <ChakraProvider>
        <Box textAlign="center" mt={10}>
          <Heading>Loading...</Heading>
        </Box>
      </ChakraProvider>
    );
  }

  return ( 
    <ChakraProvider>
      <Router>
        {isAuthenticated ? (
          <>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/settings" element={<Settings />} />
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="/login" element={
              <Box textAlign="center" mt={10}>
                <VStack spacing={4}>
                  <Heading>Login</Heading>
                  <Button
                    as="a"
                    href={`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_SERVER_URL : process.env.REACT_APP_SERVER_URL}/auth/google`}
                    colorScheme="blue"
                  >
                    Login with Google
                  </Button>
                </VStack>
              </Box>
            } />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        )}
      </Router>
    </ChakraProvider>
  );
}

export default App;
