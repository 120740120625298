import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Flex, Link, Circle, IconButton, useToast, Button } from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';

function Header() {
  const [feedStatus, setFeedStatus] = useState({ allFeedsSuccessful: true });
  const [isRefreshing, setIsRefreshing] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    fetchFeedStatus();
  }, []);

  const fetchFeedStatus = async () => {
    try {
      const response = await axios.get('/api/rss/feed-status');
      setFeedStatus(response.data);
    } catch (error) {
      console.error('Error fetching feed status:', error);
    }
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      await axios.get('/api/rss/relevant-articles?forceRefresh=true');
      toast({
        title: "Feeds refreshed",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchFeedStatus();
    } catch (error) {
      console.error('Error refreshing feeds:', error);
      toast({
        title: "Error refreshing feeds",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await axios.get('/api/auth/logout');
      if (response.data.success) {
        navigate('/login');
      } else {
        console.error('Logout was not successful');
        toast({
          title: "Logout failed",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error logging out:', error);
      toast({
        title: "Error logging out",
        description: "Please try again",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box as="header" bg="gray.100" py={4}>
      <Flex as="nav" align="center" justify="space-between" wrap="wrap" maxW="1200px" mx="auto" px={4}>
        <Link as={RouterLink} to="/" fontWeight="bold">
          Home
        </Link>
        <Flex align="center">
          <Link as={RouterLink} to="/settings" mr={2}>
            Settings
          </Link>
          <IconButton
            icon={<RepeatIcon />}
            onClick={handleRefresh}
            isLoading={isRefreshing}
            mr={2}
            aria-label="Refresh feeds"
          />
          <Circle size="10px" bg={feedStatus.allFeedsSuccessful ? 'green.500' : 'red.500'} mr={2} />
          <Button onClick={handleLogout}>Logout</Button>
        </Flex>
      </Flex>
    </Box>
  );
}

export default Header;