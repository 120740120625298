import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  useToast,
  Container,
  Spinner,
  HStack,
} from '@chakra-ui/react';

function Home() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/rss/relevant-articles');
      setArticles(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch articles. Please try again later.');
      setLoading(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: "Copied to clipboard",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }, (err) => {
      toast({
        title: "Failed to copy",
        description: "Please try again",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    });
  };

  const markAsPublished = async (url) => {
    try {
      await axios.post('/api/rss/mark-published', { url });
      setArticles(articles.filter(article => article.link !== url));
      toast({
        title: "Article marked as published",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error marking article as published:', error);
      toast({
        title: "Failed to mark article as published",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return (
      <Container centerContent>
        <Spinner size="xl" mt="20" />
      </Container>
    );
  }   

  if (error) {
    return (
      <Container centerContent>
        <Text color="red.500" fontSize="xl" mt="20">{error}</Text>
      </Container>
    );  
  }

  return (
    <Container maxW="container.xl" py={5}>
      <Heading as="h1" size="xl" textAlign="center" mb={6}>
        Lastest Healthcare IT Articles
      </Heading>
      <VStack spacing={6} align="stretch">
        {articles.map((article, index) => (
          <Box key={index} p={5} shadow="md" borderWidth="1px" borderRadius="md">
            <Heading as="h2" size="lg" mb={2}>
              <a href={article.link} target="_blank" rel="noopener noreferrer">
                {article.title}
              </a>
            </Heading>
            <Text fontSize="sm" color="gray.500" mb={2}>
              Published: {new Date(article.pubDate).toLocaleDateString()}
            </Text>
            <Text fontSize="md" fontWeight="bold" mb={2}>
              Relevance Score: {article.relevanceScore}/10
            </Text>
            <Text fontSize="md" fontWeight="bold" mb={2}>
              LinkedIn Post:
            </Text>
            <Text fontSize="sm" whiteSpace="pre-wrap">{article.linkedInPost}</Text>
            <HStack spacing={4} mt={2}>
              <Button colorScheme="linkedin" size="sm" onClick={() => copyToClipboard(article.linkedInPost)}>
                Copy LinkedIn Post
              </Button>
              <Button colorScheme="green" size="sm" onClick={() => markAsPublished(article.link)}>
                Mark as Published
              </Button>
            </HStack>
          </Box>
        ))}
      </VStack>
    </Container>
  );
}

export default Home;